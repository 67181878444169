import { css } from 'styled-components'
import easing from '@/styles/easing'

export const appear = ({ startTransform = `translateY(100px)`, duration = 0.7, delay = 0, alsoDisappear = false }) => {
  return css`
    opacity: 0;
    transform: ${startTransform};
    transition: opacity linear, transform ${easing.outStrong};
    transition-duration: ${alsoDisappear ? duration : 0}s;
    transition-delay: ${alsoDisappear ? delay : 0}s;
    ${props =>
      props.inViewport &&
      css`
        transition-duration: ${duration}s;
        transition-delay: ${delay}s;
        opacity: 1;
        transform: translate3d(0, 0, 0);
      `};
  `
}
