import { css } from 'styled-components'
import { theme } from '@/styles/color'

export const scrollbarStyle = css`
  scrollbar-color: ${theme.interactive.base} transparent;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-thumb {
    transition: background-color 0.5s;
    background-color: ${theme.interactive.base};
    border-radius: 10rem;
    &:hover,
    &:active {
      background-color: ${theme.interactive.highlight};
    }
  }
  &::-webkit-scrollbar-track-piece:start {
    background: transparent;
  }
  &::-webkit-scrollbar-track-piece:end {
    background: transparent;
  }
`
