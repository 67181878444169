import { PaginationContainer } from './styles'
import Item from './Item'

const Pagination = ({ extraStyle, items, selectHandler, horizontal = false, flipped = false, hideLabels = false }) => {
  if (!items || items.length < 1) return null
  return (
    <PaginationContainer extraStyle={extraStyle} horizontal={horizontal}>
      {items.map((item, index) => {
        return (
          <Item
            key={index}
            index={index}
            hideLabel={hideLabels}
            {...item}
            selectHandler={selectHandler}
            horizontal={horizontal}
            flipped={flipped}
          />
        )
      })}
    </PaginationContainer>
  )
}

export default Pagination
