import styled from 'styled-components'
import { breakPoints } from '@/styles/layout'
import { useEffect, useRef } from 'react'

const Img = styled.img`
  ${props => props.extraStyle}
`

const getSrcSetAndWidth = (image, desktopSizeFactor, mobileSizeFactor) => {
  if (!image.formats) return {}
  const allFormatsSimplified = [{ url: image.url, width: image.width }]
  for (const [formatName, format] of Object.entries(image.formats)) {
    allFormatsSimplified.push({ url: format.url, width: format.width })
  }
  allFormatsSimplified.sort((a, b) => a.width - b.width)
  const srcSetArray = allFormatsSimplified.map(format => `${format.url} ${format.width}w`)
  const sizesArray = Object.values(breakPoints).map(breakpoint => {
    const { max } = breakpoint
    const isDesktop = max >= breakPoints.medium.max
    const imageWidthLimit = 1500
    return `(max-width: ${max}px) ${Math.min(
      max * (isDesktop ? desktopSizeFactor : mobileSizeFactor),
      imageWidthLimit
    )}px`
  })
  return { srcSet: srcSetArray.join(', '), sizes: sizesArray.join(', ') }
}

const Image = ({
  image,
  extraStyle,
  loadHandler = () => {},
  alt = '',
  desktopSizeFactor = 1,
  mobileSizeFactor = 1,
}) => {
  if (!image) return null

  const imgRef = useRef()

  useEffect(() => {
    if (imgRef.current.complete) loadHandler()
  }, [])

  const altText = alt !== '' ? alt : image.alternativeText
  return (
    <Img
      ref={imgRef}
      alt={altText}
      onLoad={loadHandler}
      extraStyle={extraStyle}
      src={image.url}
      {...getSrcSetAndWidth(image, desktopSizeFactor, mobileSizeFactor)}
    />
  )
}

export default Image
