import {
  BackgroundGridContainer,
  MaxWidth,
  AnimatedGradient,
  GridBoxContainer,
  LightLayer,
  GridPartContainer,
  FadeOverlay,
  FullWidth,
  Spacer,
  Filler,
  TransitionGradient,
} from './styles'
import GridBoxes from './assets/gridBoxes.svg'
import GridBits from './assets/gridBits.svg'
import { useEffectStore } from '@/service/EffectService'
import { TRANSITION, useTransitionStore } from '@/service/TransitionService'

const GridPart = ({ withoutAnimation }) => {
  return (
    <GridPartContainer withoutAnimation={withoutAnimation}>
      <GridBoxes />
      <GridBits />
    </GridPartContainer>
  )
}

const BackgroundGrid = ({
  withoutAnimation = false,
  unlimitedWidth = false,
  dark = true,
  inner = false,
  Background = AnimatedGradient,
  opacity = 1,
  length = 1,
}) => {
  const gridPartsNeeded = length * 2
  const transition = useTransitionStore(state => state.transition)
  const outTransition = opacity === 1 && transition === TRANSITION.OUT && !!transition
  const shouldAnimateGradient =
    useEffectStore(state => state.animateGridBackgroundGradient) && !withoutAnimation && dark
  const shouldAnimateBits = useEffectStore(state => state.animateGridBackgroundBits) && !withoutAnimation
  const gridParts = []
  for (var i = 0; i < gridPartsNeeded; i++) {
    gridParts.push(<GridPart key={i} withoutAnimation={!shouldAnimateBits} />)
  }
  return (
    <BackgroundGridContainer>
      <Background withoutAnimation={!shouldAnimateGradient} />
      <TransitionGradient out={outTransition} />
      {!inner && <FadeOverlay dark={dark} />}
      {!dark && <LightLayer />}
      <FullWidth>
        <Spacer dark={dark} />
        <MaxWidth deactivate={unlimitedWidth}>
          <GridBoxContainer dark={dark} opacity={opacity}>
            {gridParts}
            <Filler />
          </GridBoxContainer>
        </MaxWidth>
        <Spacer dark={dark} />
      </FullWidth>
    </BackgroundGridContainer>
  )
}

export default BackgroundGrid
