import { ItemContainer, Line, Label } from './styles'

const Item = ({ title, active, horizontal, selectHandler, itemId, hideLabel, flipped }) => {
  return (
    <ItemContainer
      onClick={() => {
        selectHandler(itemId)
      }}
      active={active}
      horizontal={horizontal}
    >
      <Line horizontal={horizontal} flipped={flipped} />
      {!hideLabel && <Label horizontal={horizontal}>{title}</Label>}
    </ItemContainer>
  )
}

export default Item
