import { css } from 'styled-components'

export const breakPoints = {
  extraSmall: { min: 0, max: 374 },
  small: { min: 375, max: 768 },
  medium: { min: 769, max: 1365 },
  large: { min: 1366, max: 1920 },
  extraLarge: { min: 1921, max: 2560 },
  huge: { min: 2561, max: 99999 },
}

export const maxWidth = breakPoints.extraLarge.min

export const mediaMinPx = (pixel, style) => css`
  @media only screen and (min-width: ${pixel}px) {
    ${style}
  }
`

export const mediaMaxPx = (pixel, style) => css`
  @media only screen and (max-width: ${pixel}px) {
    ${style}
  }
`

export const mediaMinMaxPx = (minPixel, maxPixel, style) => css`
  ${mediaMinPx(minPixel, mediaMaxPx(maxPixel, style))}
`

export const mediaMin = (breakpoint, style) => css`
  ${mediaMinPx(breakPoints[breakpoint].min, style)}
`

export const mediaMax = (breakpoint, style) => css`
  ${mediaMaxPx(breakPoints[breakpoint].max, style)}
`

export const mediaMinMax = (breakpointMin, breakpointMax, style) => css`
  ${mediaMin(breakpointMin, mediaMax(breakpointMax, style))}
`

export const extraSmall = style => css`
  ${mediaMinMax('extraSmall', 'extraSmall', style)}
`

export const small = style => css`
  ${mediaMinMax('small', 'small', style)}
`

export const medium = style => css`
  ${mediaMinMax('medium', 'medium', style)}
`

export const large = style => css`
  ${mediaMinMax('large', 'large', style)}
`

export const extraLarge = style => css`
  ${mediaMinMax('extraLarge', 'extraLarge', style)}
`

export const fullSize = css`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

export const fullSizeAndAbsolute = css`
  ${fullSize};
  position: absolute;
`

// For centering horizontally.
// Consider using 'margin: auto', 'text-align: center' or something flexbox
export const centerH = css`
  left: 50%;
  transform: translate3d(-50%, 0, 0);
`

export const centerV = css`
  top: 50%;
  transform: translate3d(0, -50%, 0);
`

export const center = css`
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
`
