import { css } from 'styled-components'

export const neueMachinaFontFamily = ['neueMachina', 'sans-serif']
export const trasandinaFontFamily = ['trasandina', 'sans-serif']

export const fontStyle = family => css`
  font-family: ${family.map((ff, i) => `'${ff}'${i < family.length - 1 ? ',' : ''}`)};
`

export const neueMachinaFontStyle = css`
  ${fontStyle(neueMachinaFontFamily)};
`

export const trasandinaFontStyle = css`
  ${fontStyle(trasandinaFontFamily)};
`
