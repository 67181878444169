import create from 'zustand'

const getFromLocalStorage = (keyName, initialValue) => {
  try {
    const item = window.localStorage.getItem(keyName)
    if (item === null) {
      window.localStorage.setItem(keyName, initialValue)
      return initialValue
    }
    return item === 'true'
  } catch (e) {
    console.log(e)
  }
}

export const useEffectStore = create(set => ({
  animateGridBackgroundGradient: true,
  animateGridBackgroundBits: true,
  showContentBackgroundGradient: true,
  init: () => {
    set({ animateGridBackgroundGradient: getFromLocalStorage('animateGridBackgroundGradient', true) })
    set({ animateGridBackgroundBits: getFromLocalStorage('animateGridBackgroundBits', true) })
    set({ showContentBackgroundGradient: getFromLocalStorage('showContentBackgroundGradient', true) })
  },
}))
