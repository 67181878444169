import { css } from 'styled-components'
import { colors, theme } from '@/styles/color'

export const primaryButtonStyle = css`
  background-color: ${theme.interactive.highlight};
  color: ${theme.background};
  border: none;
  font-size: 16px;
  line-height: 14px;
  padding: 18px 22px;
  min-width: 190px;
  @media (pointer: fine) {
    &:hover,
    &:focus {
      outline: none;
      background-color: ${theme.interactive.base};
      color: ${theme.font};
    }
  }
  ${props =>
    props.disabled &&
    css`
      background-color: ${colors.grey.dark};
      color: ${colors.grey.base};
    `};
`
