import styled, { css } from 'styled-components'
import { neueMachinaFontStyle, trasandinaFontStyle } from '@/styles/font'
import { extraSmall, small } from '@/styles/layout'
import { colors } from '@/styles/color'

export const baseTextStyle = css`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`

export const headlineStyle = css`
  line-height: 1em;
  ${trasandinaFontStyle};
  font-weight: 200;
  strong {
    font-weight: 400;
    ${neueMachinaFontStyle};
  }
`

export const H1 = styled.h1`
  font-size: 86px;
  ${headlineStyle}
  ${neueMachinaFontStyle};
  font-weight: 400;
  ${small(
    css`
      font-size: 48px;
    `
  )}
  ${extraSmall(
    css`
      font-size: 32px;
    `
  )}
`

export const H2 = styled.h2`
  // font-size: 68px;
  // ${headlineStyle}
  // H3 alle durch H2 ersetzt, also Style übernommen
  font-size: 38px;
  ${headlineStyle};
  line-height: 1.21em;
  
`

export const H3 = styled.h3`
  font-size: 38px;
  ${headlineStyle};
  line-height: 1.21em;
`

export const H4 = styled.h4`
  font-size: 26px;
  ${headlineStyle}
`

export const H5 = styled.h5`
  ${trasandinaFontStyle};
  font-weight: 400;
  font-size: 16px;
`




export const pseudoH1 = styled.div`
  font-size: 68px;
  ${headlineStyle}
`
export const pseudoH2 = styled.div`
  font-size: 68px;
  ${headlineStyle}
`

export const pseudoH3 = styled.div`
  font-size: 38px;
  ${headlineStyle};
  line-height: 1.21em;
`

export const pseudoH4 = styled.div`
  font-size: 26px;
  ${headlineStyle}
`

export const pseudoH5 = styled.div`
  ${trasandinaFontStyle};
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 1em;
`






export const outlineStyle = color => css`
  color: transparent;
  -webkit-text-stroke: 1px ${color};
`

export const lightOutlineStyle = outlineStyle(colors.grey.light)

const getWordsFromChildren = children => {
  let words = []
  if (typeof children === 'string') words = words.concat(children.split(' '))
  else if (Array.isArray(children)) {
    children.forEach(child => {
      words = words.concat(getWordsFromChildren(child))
    })
  } else if (typeof children === 'object') {
    words = words.concat(getWordsFromChildren(children.props.children))
  }
  return words
}

export const dynamicFontSizeByMaxLetters = (targetFontSize, maxLetters, maxVw) => {
  return css`
    font-size: min(${(maxVw * 1.81) / maxLetters}vw, ${targetFontSize});
  `
}

export const dynamicFontSize = (targetFontSize, children, maxVw, dontBreak = false) => {
  const words = getWordsFromChildren(children)
  let maxLetters = 0
  if (dontBreak) {
    maxLetters = words.toString().length
  } else {
    words.sort((a, b) => b.length - a.length)
    if (words.length === 0) {
      maxLetters = 5
    } else {
      maxLetters = words[0].length
    }
  }
  return dynamicFontSizeByMaxLetters(targetFontSize, maxLetters, maxVw)
}
