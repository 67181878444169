export const colors = {
  grey: {
    light: '#DBDBDB',
    base: '#7A7A7A',
    dark: '#343434',
  },
  blue: {
    dark: '#0b0f14',
  },
  moss: {
    veryLight: '#D3E8D5',
    light: '#43FFBB',
    base: '#144B46',
    dark: '#143732',
    veryDark: '#101511',
  },
  white: '#FBFBFB',
}

export const theme = {
  background: colors.moss.veryDark,
  font: colors.white,
  interactive: {
    base: colors.moss.base,
    highlight: colors.moss.light,
  },
}
