import create from 'zustand'
import Router from 'next/router'

export const TRANSITION = {
  IN: 'TRANSITION.IN',
  OUT: 'TRANSITION.OUT',
}

export const transitionOutDurationMS = 500
export const transitionInDurationMS = 1000

export const useTransitionStore = create((set, get) => ({
  transition: TRANSITION.OUT,
  transitionOngoing: false,
  transitionHref: null,
  transitionLocale: null,
  transitionTimeout: null,
  router: null,

  transitionTo: (href, locale) => {
    Router.prefetch(href, null, { locale })
    set({
      transitionHref: href,
      transitionLocale: locale,
    })
    get().transitionOut()
  },

  transitionOut: () => {
    clearTimeout(get().transitionTimeout)
    set({
      transition: TRANSITION.OUT,
      transitionOngoing: true,
      transitionTimeout: setTimeout(() => {
        Router.push(get().transitionHref, null, { locale: get().transitionLocale })
        set({ transitionOngoing: false })
      }, transitionOutDurationMS),
    })
  },

  transitionIn: () => {
    clearTimeout(get().transitionTimeout)
    set({
      transition: TRANSITION.IN,
      transitionOngoing: true,
      transitionTimeout: setTimeout(() => {
        set({ transitionOngoing: false })
      }, transitionInDurationMS),
    })
  },
}))
