import styled, { css, keyframes } from 'styled-components'
import { fullSizeAndAbsolute, maxWidth } from '@/styles/layout'
import { colors } from '@/styles/color'
import easing from '@/styles/easing'

export const BackgroundGridContainer = styled.div`
  ${fullSizeAndAbsolute};
  min-height: 100vh;
  overflow: hidden;
`

const gradientAnimation = keyframes`
  0% {
    transform: translate3d(-50vw, 0, 0);
  }
  100% {
    transform: translate3d(50vw, 0, 0);
  }
`

export const LightLayer = styled.div`
  ${fullSizeAndAbsolute};
  background-color: ${colors.white};
  opacity: 0.93;
`

export const FadeOverlay = styled.div`
  ${fullSizeAndAbsolute};
  bottom: auto;
  height: 33vh;
  background: linear-gradient(0deg, transparent, ${props => (props.dark ? colors.blue.dark : colors.white)});
`

export const AnimatedGradient = styled.div`
  ${fullSizeAndAbsolute};
  width: 200vw;
  height: 100%;
  top: 0;
  left: -50vw;
  background: linear-gradient(
    -45deg,
    ${colors.moss.base},
    ${colors.moss.dark},
    ${colors.moss.veryDark},
    ${colors.moss.base},
    ${colors.moss.dark},
    ${colors.moss.base},
    ${colors.moss.veryDark},
    ${colors.moss.base},
    ${colors.blue.dark},
    ${colors.moss.dark},
    ${colors.moss.base},
    ${colors.moss.veryDark},
    ${colors.moss.base},
    ${colors.blue.dark},
    ${colors.moss.dark}
  );
  background-size: 100% 100%;
  ${props =>
    !props.withoutAnimation &&
    css`
      animation: ${gradientAnimation} 45s ease infinite;
    `};
`

export const TransitionGradient = styled.div`
  ${fullSizeAndAbsolute};
  background: linear-gradient(
    ${colors.blue.dark}00,
    ${colors.moss.light}99,
    ${colors.moss.veryLight},
    ${colors.moss.light}99,
    ${colors.blue.dark}00
  );
  opacity: 0.4;
  transition: transform 4s ${easing.outStrong};
  transition-delay: 0.7s;
  transform: ${props => (props.out ? `translateY(-100%)` : `translateY(100%)`)};
`

export const FullWidth = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: stretch;
  flex-direction: row;
`

export const Spacer = styled.div`
  height: 100%;
  background-color: ${props => (props.dark ? colors.blue.dark : colors.white)};
  flex-grow: 1;
  position: relative;
`

export const MaxWidth = styled.div`
  height: 100%;
  position: relative;
  flex-basis: 100%;
  flex-grow: 1;
  ${props =>
    !props.deactivate &&
    css`
      max-width: ${maxWidth}px;
    `};
`

export const Filler = styled.div`
  flex-grow: 1;
`

export const GridBoxContainer = styled.div`
  ${fullSizeAndAbsolute};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  opacity: ${props => props.opacity};
  ${Filler} {
    background-color: ${props => (props.dark ? colors.blue.dark : colors.white)};
  }
  svg {
    display: block;
    .box {
      fill: ${props => (props.dark ? colors.blue.dark : colors.white)};
    }
    .bit {
      fill: ${props => (props.dark ? colors.moss.base : '#E3E8E8')};
    }
  }
`

const upAndDown = keyframes`
  0% {
    transform: translateY(-200px);
  }
  50% {
    transform: translateY(200px);
  }
  100% {
    transform: translateY(-200px);
  }
`

export const GridPartContainer = styled.div`
  position: relative;
  padding-top: 218.75%;
  svg {
    ${fullSizeAndAbsolute};
    top: 0;
  }
  ${props =>
    !props.withoutAnimation &&
    css`
      svg {
        .bit {
          &.vSnake {
            animation: ${upAndDown} 30s ease infinite;
          }
        }
      }
    `};
`
