import styled from 'styled-components'
import { maxWidth } from '@/styles/layout'

export const FullWidthContainer = styled.div`
  height: 100%;
`

export const MaxWidthContainer = styled.div`
  height: 100%;
  position: relative;
  max-width: ${maxWidth}px;
  margin: 0 auto;
`
