import styled, { css } from 'styled-components'
import { primaryButtonStyle } from './Primary'
import { secondaryButtonStyle } from './Secondary'
import { outlineButtonStyle } from './Outline'
import { neueMachinaFontStyle } from '@/styles/font'
import { colors } from '@/styles/color'

const commonButtonStyles = css`
  cursor: pointer;
  position: relative;
  text-decoration: none;
  ${neueMachinaFontStyle};
  display: inline-block;
  box-sizing: border-box;
  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
    `};
  ${props => (props.secondary ? secondaryButtonStyle : primaryButtonStyle)};
  ${props => props.outline && outlineButtonStyle};
  ${props => props.extraStyle};
  ${props =>
    props.isOnWhite &&
    css`
      color: ${colors.moss.base};
    `}
`

export const NonLinkButton = styled.button`
  ${commonButtonStyles};
`

const Button = styled.a`
  ${commonButtonStyles};
`

export default Button
