import { FullWidthContainer, MaxWidthContainer } from './styles'

const MaxWidth = ({ children }) => {
  return (
    <FullWidthContainer>
      <MaxWidthContainer>{children}</MaxWidthContainer>
    </FullWidthContainer>
  )
}

export default MaxWidth
