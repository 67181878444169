import create from 'zustand'
import { medium } from '@/styles/layout'

export const useMediaLayerStore = create((set, get) => ({
  media: [],
  activeIndex: 0,
  inTransition: false,
  transitionTimeout: null,
  selectMedium: index => {
    clearTimeout(get().transitionTimeout)
    if (!get().inTransition) set({ activeIndex: index, inTransition: true })
    set({
      transitionTimeout: setTimeout(() => {
        set({ inTransition: false })
      }, [750]),
    })
  },
  selectNextMedium: () => {
    const targetIndex = get().activeIndex === get().media.length - 1 ? 0 : get().activeIndex + 1
    get().selectMedium(targetIndex)
  },
  selectPrevMedium: () => {
    const targetIndex = get().activeIndex === 0 ? get().media.length - 1 : get().activeIndex - 1
    get().selectMedium(targetIndex)
  },
  openMedia: (media, startIndex = 0) => {
    clearTimeout(get().transitionTimeout)
    set({ media, activeIndex: startIndex, inTransition: false })
  },
  closeMedia: () => {
    clearTimeout(get().transitionTimeout)
    set({ media: [], activeIndex: 0, inTransition: false })
  },
  setInTransition: inTransition => {
    set({ inTransition })
  },
}))
