import styled, { css } from 'styled-components'
import { column } from '@/styles/grid'
import { center, extraSmall, fullSizeAndAbsolute, mediaMax, medium, small } from '@/styles/layout'
import { dynamicFontSize, H1, H2 } from '@/styles/text'
import { colors } from '@/styles/color'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { appear } from '@/styles/animation'

const bigScreenColumns = 8
const smallScreenColumns = 10

export const BigImageSliderContainer = styled.div`
  margin-top: 50px;
`

export const AspectRatioContainer = styled.div`
  padding-top: ${((bigScreenColumns * 10) / 16) * 9}%;
  min-height: 370px;
  position: relative;
  background-color: ${colors.moss.dark};
  ${appear({ startTransform: `translateX(25%)` })}
  ${column(bigScreenColumns, 2)}
  ${mediaMax(
    'medium',
    css`
      padding-top: ${((smallScreenColumns * 10) / 16) * 9}%;
      ${column(smallScreenColumns, 0)}
    `
  )}
`

export const SliderContainer = styled.div`
  background-color: ${colors.moss.dark};
  ${fullSizeAndAbsolute};
  transition: opacity 0.7s;
  opacity: ${props => (props.show ? 1 : 0)};
  .slick-slide {
    outline: none;
  }
  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide {
    height: 100%;
  }
  .slick-slide > div {
    height: 100%;
  }
`

export const Headline = styled(H1)`
  ${column(8, 1)};
  position: relative;
  z-index: 1;
  margin-bottom: -0.291em;
  ${appear({ startTransform: `translateY(-40px)` })}
  ${props => dynamicFontSize('86px', props.children, 80)};
  ${small(
    css`
      ${props => dynamicFontSize('48px', props.children, 80)};
    `
  )}
  ${extraSmall(
    css`
      ${props => dynamicFontSize('32px', props.children, 80)};
    `
  )}
`

export const Slide = styled.div`
  height: 100%;
  overflow: hidden;
  position: relative;
  outline: none;
`

export const imageStyle = css`
  object-fit: cover;
  min-width: 100%;
  max-height: 100%;
  min-height: 100%;
  position: absolute;
  ${center}
`

export const PaginationContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0 10px;
`

export const PaginationBackground = styled.div`
  ${fullSizeAndAbsolute};
  top: 50%;
  background: linear-gradient(0deg, ${colors.blue.dark} 0%, ${colors.blue.dark}be 100%);
`

export const paginationStyle = css`
  display: flex;
  bottom: 0;
`

export const copyrightLabelStyle = css`
  max-width: 40%;
  ${small(css`
    max-width: 50%;
  `)}
  ${extraSmall(css`
    max-width: 40%;
  `)}
`

export const SlideTitle = styled(H2)`
  margin: 0;
  ${mediaMax(
    'medium',
    css`
      font-size: 24px;
    `
  )}
`

export const Description = styled.p``

export const SlideInfoContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: indianred;
  background: linear-gradient(0deg, ${colors.blue.dark}ff 0%, ${colors.blue.dark}bb 100%);
  padding: 20px;
  width: 37.5%;
  box-sizing: border-box;
  ${medium(css`
    width: 40%;
  `)}
  ${small(css`
    width: 50%;
  `)}
  ${extraSmall(css`
    width: 60%;
    ${SlideTitle}, ${Description} {
      display: none;
    }
    ${props =>
      !props.hasVideo &&
      css`
        display: none;
      `};
  `)}
  ${mediaMax(
    'small',
    css`
      padding: 15px 20px;
    `
  )}
`

export const PlayButtonContainer = styled.div`
  padding: 25px 0 15px;
  ${mediaMax(
    'small',
    css`
      padding: 10px 0;
    `
  )}
`
