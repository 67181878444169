import React from 'react'
import { useTransitionStore } from '@/service/TransitionService'
import { LinkContainer } from './styles'
import { useRouter } from 'next/router'

export const getPageLikeFromLinkObject = link => {
  return link.page
    ? link.page
    : link.project
    ? link.project
    : link.location
    ? link.location
    : link.job
    ? link.job
    : null
}

const Linky = ({ to, children, Component = LinkContainer }) => {
  const router = useRouter()
  if (!to || typeof to === 'string') {
    console.warn(`Linky 'to' cannot be null or string`)
    return null
  }

  let href = ''
  let fullHref = ''
  let newTab = false
  let locale = router.locale

  if (to.externalUrl) {
    href = to.externalUrl
    fullHref = href
    newTab = true
  } else {
    const targetPage = to.slug != null ? to : getPageLikeFromLinkObject(to)
    if (!targetPage) return null
    href = `${targetPage['path'] ? '/' + targetPage['path'] : ''}/${targetPage['slug']}`
    if (targetPage.locale) {
      locale = targetPage.locale
    }
    fullHref = locale === 'de' ? href : '/' + locale + href

    // remove trailing slash but allow '/'
    if (fullHref.length > 1 && fullHref.endsWith('/')) {
      fullHref = fullHref.substr(0, fullHref.length - 1)
    }
  }

  const additionalProps = {
    href: fullHref,
  }

  if (newTab) {
    additionalProps.target = '_blank'
    additionalProps.rel = 'noreferrer'
  }

  const mouseEnterHandler = () => {
    if (!newTab) router.prefetch(href, null, { locale })
  }

  const clickHandler = e => {
    if (additionalProps.href === window.location.pathname) {
      e.preventDefault()
      window.scrollTo({ top: 0, behavior: 'smooth' })
    } else if (!newTab) {
      e.preventDefault()
      useTransitionStore.getState().transitionTo(href, locale)
    }
  }

  return (
    <Component onMouseEnter={mouseEnterHandler} onClick={clickHandler}>
      {React.cloneElement(children, additionalProps)}
    </Component>
  )
}

export function makeRoot() {
  return { slug: '', path: '' }
}

export default Linky
