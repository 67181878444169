import { css } from 'styled-components'

import { colors, theme } from '@/styles/color'

const hPadding = 9
const vPadding = 11

const underlineStyle = css`
  content: '';
  position: absolute;
  width: calc(100% - ${hPadding * 2}px);
  height: 1px;
  left: ${hPadding}px;
  bottom: ${vPadding - 6}px;
  background-color: ${colors.white};
`

export const highlightUnderlineStyle = css`
  bottom: ${vPadding - 7}px;
  transform-origin: left center;
  transform: scaleX(0.2);
  transition: transform 0.25s ease;
`

export const secondaryButtonStyle = css`
  color: ${theme.font};
  background: transparent;
  border: none;
  font-size: 16px;
  line-height: 14px;
  padding: ${hPadding}px ${vPadding}px;
  @media (pointer: fine) {
    &:hover,
    &:focus {
      outline: none;
      &::after {
        transform: scaleX(1);
      }
    }
    &:hover {
      color: ${theme.interactive.highlight};
    }
    &:focus {
      color: ${theme.font};
    }
  }
  ${props => props.extraStyle};
  &::before,
  &::after {
    ${underlineStyle};
  }
  &::after {
    ${highlightUnderlineStyle};
  }
  ${props =>
    props.disabled &&
    css`
      color: ${colors.grey.base};
      &::before,
      &::after {
        background-color: ${colors.grey.base};
      }
    `};
`
