import styled, { css } from 'styled-components'

const columnCount = 10

export const row = css`
  position: relative;
  display: flex;
  flex: 1 1 content;
  justify-content: flex-start;
  flex-wrap: wrap;
`

export const column = (columns, offsetColumns = 0) => css`
  flex-basis: ${(100 * columns) / columnCount}%;
  box-sizing: border-box;
  margin-left: ${(100 * offsetColumns) / columnCount}%;
`

export const offsetRight = columns => css`
  margin-right: ${(100 * columns) / columnCount}%;
`

export const padding = css`
  padding: 0 10px;
`
