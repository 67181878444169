import styled, { css } from 'styled-components'
import { colors, theme } from '@/styles/color'

export const Label = styled.div`
  margin-right: ${props => (props.horizontal ? 0 : 12)}px;
  text-align: ${props => (props.horizontal ? 'center' : 'left')};
  font-size: 12px;
  transition: opacity 0.25s ease, transform 0.25s ease;
  opacity: 0;
  transform: translate3d(${props => (props.horizontal ? '-50%, -25%, 0' : '20px, 0 ,0')});
  ${props =>
    props.horizontal &&
    css`
      position: absolute;
      left: 50%;
      top: 0;
    `}
`

export const Line = styled.div`
  position: relative;
  top: ${props => (props.horizontal ? 'auto' : '12px')};
  left: ${props => (props.horizontal ? 'calc(50% - 1px)' : 'auto')};
  height: ${props => (props.horizontal ? 20 : 2)}px;
  width: ${props => (props.horizontal ? 2 : 20)}px;
  transition: transform 0.25s ease;
  transform: ${props => (props.horizontal ? 'scaleY(0.3)' : 'scaleX(0.3)')};
  transform-origin: ${props => (props.flipped ? 'left top' : 'right bottom')};
  background-color: ${colors.white};
`

export const ItemContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: ${props => (props.horizontal ? 'column-reverse' : 'row-reverse')};
  cursor: pointer;
  user-select: none;
  line-height: 24px;

  ${props =>
    props.horizontal &&
    css`
      width: 22px;
      height: 55px;
    `};

  @media (pointer: fine) {
    &:hover {
      ${Line} {
        transform: scaleX(1) scaleY(1);
      }
      ${Label} {
        opacity: 1;
        transform: translate3d(${props => (props.horizontal ? '-50%, 0, 0' : '0, 0 ,0')});
      }
    }
  }

  ${props =>
    props.active &&
    css`
      ${props =>
        !props.horizontal &&
        css`
          ${Label} {
            opacity: 1;
            transform: translate3d(10px, 0, 0);
          }
        `}
      ${Line} {
        ${props =>
          props.horizontal
            ? css`
                transform: scaleY(0.5);
              `
            : css`
                transform: scaleX(0.5);
              `}
        background-color: ${theme.interactive.highlight};
      }
    `}
`
