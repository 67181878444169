import { css } from 'styled-components'
import { colors } from '@/styles/color'

export const outlineButtonStyle = css`
  background-color: transparent;
  border: 2px solid ${colors.moss.light};
  box-sizing: border-box;
  @media (pointer: fine) {
    &:hover,
    &:focus {
      background-color: ${colors.moss.light};
      color: ${colors.blue.dark};
    }
  }
`
